<template>
  <main>
         <div class="mentions">
           <img src="/top-form-mobile.png">
          Notre jeu est terminé,<br>merci pour votre compréhension.
        </div>
  </main>
</template>
<script>

export default {
  name: 'finished',
}
</script>